import React from 'react'

const Contact = () => {
  return (
    <div name='contact' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4'>
        <form action='https://getform.io/f/nelJAJbK' method="POST" className='flex flex-col max-w-[600px] w-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-pink-600 text-gray-300'>
                  Contact  
                </p>
                <p className='text-gray-300 py-4'>
                    Submit the form below or send me an email directly at - hsg_001@hotmail.com
                </p>
            </div>
            <input className='bg-[#ccd6f6] p-2' type='text' placeholder='Name' name='name'>
            </input>
            <break>
            </break>
            <input className='my-4 p-2 bg-[#ccd6f6]' type='email' placeholder='Email' name='email'>
            </input>
            <textarea className='bg-[#ccd6f6] p-2' rows="10" name="message" placeholder="Message" >
            </textarea>
            <button className='text-white border-2 hover:bg-pink-600 px-4 py-3 my-8 mx-auto flex items-center'>Submit</button>
        </form>    
    
    </div>
  )
}

export default Contact